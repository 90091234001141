import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/Gallery.css";
import NavBar from "../components/NavBar";
import Header from "../components/header";
import Footer from "../components/Footer";

// import Services1 from "../assets/gallery1_conmhe.png";
// import Services2 from "../assets/gallery2_lgytm6.png";
// import Services3 from "../assets/gallery3_ar8gig.png";
// import Services4 from "../assets/gallery4_uq2wuj.png";
// import Services5 from "../assets/gallery5_xnvldc.png";
// import Services6 from "../assets/gallery6_hzigcv.png";

const GalleryBanner =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810952/gallery_banner_afxfx2.jpg";
const SearchIcon =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810971/search_kn5zsw.png";
const Services1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810952/gallery1_conmhe.png";
const Services2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810949/gallery2_lgytm6.png";
const Services3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810950/gallery3_ar8gig.png";
const Services4 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810951/gallery4_uq2wuj.png";
const Services5 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810951/gallery5_xnvldc.png";
const Services6 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810952/gallery6_hzigcv.png";

const images = [
  {
    name: "service1",
    image: Services1,
  },
  {
    name: "service2",
    image: Services2,
  },
  {
    name: "service3",
    image: Services3,
  },
  {
    name: "service4",
    image: Services4,
  },
  {
    name: "service5",
    image: Services5,
  },
  {
    name: "service6",
    image: Services6,
  },
];

function MyGallery() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <div className="main_gallery_container" style={{ backgroundColor: "#FFF" }}>
      <NavBar cname="Our Gallery"></NavBar>
      <Header
        heading="Discover Luxury in Every Detail"
        banner={GalleryBanner}
      ></Header>
      <Container className="main_gallery_body">
        <h1 className="heading">
          Explore Our <span>Gallery</span>
        </h1>
        <p className="description">
          Each image captures the essence of our commitment to excellence,
          highlighting the meticulous attention to detail and unparalleled
          comfort that defines our services. Explore our gallery and envision
          yourself experiencing the ultimate in luxury travel with us.
        </p>
        <div className="gallery_box_se">
          <div className="top">
            <Row>
              {images.length > 0 &&
                images.map((data, _idx) => (
                  <Col md="4">
                    <div className="card-box">
                      <a href={`#/ourgallery/#img_${_idx + 1}`}>
                        <img
                          className="image"
                          src={data.image}
                          alt={`Service ${_idx}`}
                        />
                        <div class="overlay">
                          <img className="icon" src={SearchIcon} alt="Icon" />
                        </div>
                      </a>
                    </div>
                  </Col>
                ))}
            </Row>
            {images.length &&
              images.map((data, _idx) => (
                <a
                  href={`#/ourgallery/img_${_idx + 1}`}
                  class="lightbox trans"
                  id={`#/ourgallery/img_${_idx + 1}`}
                >
                  <img src={data.image} alt={data.name} />
                </a>
              ))}
          </div>
        </div>
        <div className="load_btn">
          <button className="load_more">Load More</button>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default MyGallery;
