import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Inputfields from "../components/Inputfields";
import Footer from "../components/Footer";
import { useLocation, useSearchParams } from "react-router-dom";
import Location from "../assets/location_Icon_oyapgw.png"
import Date from "../assets/date_Icon_oqp7mg.png"
import Time from "../assets/Time_Icon_g2mmhg.png"
import Passenger from "../assets/Passenger_Icon_hvh0ev.png"
import Currency from "../assets/Currency_Icon_ylzk8p.png"

// Images
// const Location =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810959/location_Icon_oyapgw.png";
// const Date =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810943/date_Icon_oqp7mg.png";
// const Time =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810989/Time_Icon_g2mmhg.png";
// const Passenger =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810964/Passenger_Icon_hvh0ev.png";
// const Currency =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810943/Currency_Icon_ylzk8p.png";

const form_inputs = [
  {
    name: "Airport Transfer",
    inputs: [
      [
        {
          for: "from",
          src: Location,
          label: "Pick Up",
          type: "location",
          id: "from",
          placeholder: "Airport name",
        },
      ],
      [
        {
          for: "To",
          src: Location,
          label: "Drop Off Location",
          type: "location",
          id: "to",
          placeholder: "Address, Hotel, Airport",
        },
      ],
      [
        {
          for: "date",
          src: Date,
          label: "Pick Up Date",
          type: "date",
          id: "date",
          placeholder: "Pick Up Date",
        },
      ],
      [
        {
          for: "time",
          src: Time,
          label: "Pick Up Time",
          type: "time",
          id: "time",
          placeholder: "Pick Up Time",
        },
      ],
      [
        {
          for: "passenger",
          src: Passenger,
          label: "Passangers",
          type: "number",
          id: "passenger",
          placeholder: "1 Passenger",
        },
      ],
      [
        {
          for: "catgory",
          src: Currency,
          label: "Choose Vehicle Category",
          type: "category",
          value: ["Economy", "Business", "First Class"],
          id: "currency",
          placeholder: "USD",
        },
      ],
    ],
  },
  {
    name: "Hourly",
    inputs: [
      [
        {
          for: "from",
          src: Location,
          label: "Pick Up Point",
          type: "location",
          id: "from",
          placeholder: "Address, Hotel, Airport",
        },
      ],
      [
        {
          for: "To",
          src: Location,
          label: "Drop Off Location",
          type: "location",
          id: "to",
          placeholder: "Address, Hotel, Airport",
        },
      ],
      [
        {
          for: "date",
          src: Date,
          label: "Pick Up Date",
          type: "date",
          id: "date",
          placeholder: "MM-DD-YYYY",
        },
      ],
      [
        {
          for: "time",
          src: Time,
          label: "Pick Up Time",
          type: "time",
          id: "time",
          placeholder: "10:01",
        },
      ],
      [
        {
          for: "hours",
          src: Passenger,
          label: "Pick Up Hours",
          type: "number",
          id: "hours",
          placeholder: "hours",
        },
      ],
      [
        {
          for: "passenger",
          src: Passenger,
          label: "Passangers",
          type: "number",
          id: "passenger",
          placeholder: "1 Passenger",
        },
      ],
      [
        {
          for: "catgory",
          src: Currency,
          label: "Choose Vehicle Category",
          type: "category",
          value: ["Economy", "Business", "First Class"],
          id: "currency",
          placeholder: "USD",
        },
      ],
    ],
  },
  {
    name: "Full Day",
    inputs: [
      [
        {
          for: "from",
          src: Location,
          label: "Pick Up Point",
          type: "location",
          id: "from",
          placeholder: "Address, Hotel, Airport",
        },
      ],
      [
        {
          for: "To",
          src: Location,
          label: "Drop Off Location",
          type: "location",
          id: "to",
          placeholder: "Address, Hotel, Airport",
        },
      ],
      [
        {
          for: "date",
          src: Date,
          label: "Pick Up Date",
          type: "date",
          id: "date",
          placeholder: "MM-DD-YYYY",
        },
      ],
      [
        {
          for: "time",
          src: Time,
          label: "Pick Up Time",
          type: "time",
          id: "time",
          placeholder: "10:01",
        },
      ],
      [
        {
          for: "catgory",
          src: Currency,
          label: "Choose Vehicle Category",
          type: "category",
          value: ["Economy", "Business", "First Class"],
          id: "currency",
          placeholder: "USD",
        },
      ],
      [
        {
          for: "passenger",
          src: Passenger,
          label: "Passangers",
          type: "number",
          id: "passenger",
          placeholder: "1 Passenger",
        },
      ],
    ],
  },
];

function BookingPage() {
  const location5 = useLocation();
  var [search] = new useSearchParams(location5.search);
  const [forminputs, setforminputs] = useState(0);

  useEffect(() => {
    setforminputs(search.get("selectedBtn"));
  }, []);

  return (
    <div className="booking_page">
      <NavBar />
      <Inputfields selectedBtn={form_inputs[forminputs]} />
      <Footer />
    </div>
  );
}

export default BookingPage;
