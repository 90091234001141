import React from "react";
import { Container } from "react-bootstrap";
import "./style.css";

const TextBanner = (props) => {
  const { heading, subheading } = props;
  return (
    <div className="banner-container">
      <Container>
        <div className="inner-container">
          <h2 className="heading">{heading}</h2>
          <h3 className="subheading">{subheading}</h3>
        </div>
      </Container>
    </div>
  );
};

export default TextBanner;
