import React from "react";
import "../CSS/Form.css";
const DefaultBanner =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714812077/Background-min_cbftc0.png";

const Header = ({ heading, banner }) => {
  return (
    <div
      className="main_form_container"
      style={{
        height: "600px",
        backgroundImage: banner ? `url(${banner})` : `url(${DefaultBanner})`,
      }}
    >
      <div className="container">
        <div className="left-div">
          <div className="main_text">
            <h1 className="header_heading">{heading}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
