import React from "react";
import "../CSS/Aboutprovider.css";
import { useNavigate } from "react-router-dom";
import carpic_legotp from "../assets/carpic_legotp.png";

// const carpicture =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810941/carpic_legotp.png";

const Aboutprovider = () => {
  const navigate = useNavigate();

  const navigateContact = () => navigate("/contactus");
  return (
    <div className="main_ECSprovider_container">
      <div className="left_container">
        <div className="left_container_body">
          <h1 className="upper_heading">How We Redefined Luxury</h1>
          <h1>in Transportation</h1>
          <p className="red">About ECS Providers</p>
          <p className="text">
            {" "}
            In the vibrant landscape of Riyadh Saudi Arabia, Executive Chauffeur
            Service (ECS) emerges as a beacon of opulent travel. As a leading
            provider of executive chauffeur service in KSA, we have been
            offering unmatched experiences for over 20 years. Representing the
            epitome of luxury travel, our local executive chauffeur service
            caters to the needs of luxury ride enthusiasts. Our range of
            services is extensive yet diverse. Furthermore, through our
            strategic partnerships, we ensure the provision of luxurious
            transportation in major cities worldwide. We stand as a symbol of
            the high-end car service that Riyadh is famous for. Whether your
            choice is a Mercedes S-Class or a luxury low-end Sedan, we are
            committed to enhancing your travel experience.
          </p>
          <button className="read_button" onClick={navigateContact}>
            Contact Us
          </button>
        </div>
      </div>
      <div className="right_container">
        <div className="right_container_body">
          <img src={carpic_legotp} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Aboutprovider;
