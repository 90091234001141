import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../CSS/Video.css";
import movie from "../assets/video-review_yiylzq.mp4"

// const movie =
//   "https://res.cloudinary.com/dzgzig4vx/video/upload/v1714811141/video/video-review_yiylzq.mp4";

const VideoComponent = ({ title, subTitle, description }) => {
  const [playpause, setPlayPause] = useState("play");
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleButtonClick = () => {
    if (playpause === "play") {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlayPause(playpause === "play" ? "pause" : "play");
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Container className="main_video_container">
      <h1>{title}</h1>
      <h1 className="red">{subTitle}</h1>
      <p>{description}</p>
      <div
        className="video_container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <video
          ref={videoRef}
          controls={false}
          autoPlay
          loop
          muted
          onClick={handleVideoClick}
          className={playpause === "play" && !isHovered ? "" : ""}
        >
          <source src={movie} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video_overlay_outer">
          <div className="video_overlay_inner">
            <div
              className={`playpause_btn_container ${
                playpause === "play" && !isHovered ? "hidden" : "visible"
              }`}
            >
              <button onClick={handleButtonClick} className={playpause}>
                {playpause === "play" ? (
                  <div className="pause_box">
                    <div className="pause"></div>
                  </div>
                ) : (
                  <div className="play"></div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VideoComponent;
