import React from "react";
import "../CSS/Services.css";

import serviceImg from "../assets/service.jpg";
import serviceImg2 from "../assets/service2.jpg";
import serviceImg3 from "../assets/service3.jpg";
import serviceImg4 from "../assets/service4.jpg";
import serviceImg5 from "../assets/service5.jpg";
import serviceImg6 from "../assets/service6.jpg";
// const service1 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810993/service1_mtrb3z.jpg";
// const service2 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714813946/service2_djb7ca.jpg";
// const service3 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811012/service3_d51v86.jpg";
// const service4 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810972/service4_b8wbpp.jpg";
// const service5 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811001/service5_yxb9tw.jpg";
// const service6 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811012/service6_nuorep.jpg";

const Services = () => {
  return (
    <div className="main_services_container">
      <div className="content-container">
        <h1>
          <span className="headingpart1">Your Experience is What</span>
          <span className="headingpart2">Matters the Most!</span>
        </h1>
        <p>
          Whatever your need, Executive Chauffeur Service is there when it comes
          to reliable transportation in the Kingdom of Saudi Arabia. We offer a
          complete range of superior limousine & car services for any day, any
          occasion.
        </p>
      </div>
      <div className="grid-container">
        <div className={`grid-item`}>
          <div className="image_container">
            <img src={serviceImg} alt="" />
          </div>
          <div className="text">
            <h3>Executive Corporate Travel</h3>
            <p>Professional solutions for corporate travelers.</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img src={serviceImg3} alt="" />
          </div>
          <div className="text">
            <h3>High-end Roadshow Transportation</h3>
            <p>Specialist roadshow and tour transportation</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img src={serviceImg4} alt="" />
          </div>
          <div className="text">
            <h3>Premium Airport Transfers</h3>
            <p>The most comfortable way to start or end your journey.</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img src={serviceImg5} alt="" />
          </div>
          <div className="text">
            <h3>Sophisticated Event Transportation</h3>
            <p>Expertly executed event transportation</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img src={serviceImg6} alt="" />
          </div>
          <div className="text">
            <h3>VIP Transport Solution</h3>
            <p>Personalized service to meet the Security of our VIPs.</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img src={serviceImg2} alt="" />
          </div>
          <div className="text">
            <h3>Tourist Transportation Solution</h3>
            <p>
              We provide personalized tourist private transportation all over
              the Kingdom of Saudi Arabia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
