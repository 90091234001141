import React from "react";
import { Alert } from "react-bootstrap";

const AlertBox = (props) => {
  const { isShow, type, message } = props;

  return (
    <Alert
      show={isShow}
      variant={type === 1 ? "success" : type === 2 ? "danger" : "warning"}
    >
      {message}
    </Alert>
  );
};

export default AlertBox;
