import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../CSS/AirportTransfers.css";
import useWindowDimensions from "../Utils/screenSize";
import airport1 from "../assets/airport1_ylo3bk.png";
import airport2 from "../assets/airport2_tsbdbr.png";
import airport3 from "../assets/airport3_bhkje5.png";
import airport4 from "../assets/airport4_bkct29.png";
import airport5 from "../assets/airport5_t9gscp.png";
import airport6 from "../assets/airport6_zwjfug.png";
import airport1M from "../assets/airport1m_tvuoqf.png";
import airport2M from "../assets/airport2m_g2uow4.png";
import airport3M from "../assets/airport3m_or6wby.png";
import airport4M from "../assets/airport4m_d1rlkb.png";
import airport5M from "../assets/airport5m_k89n6i.png";
import airport6M from "../assets/airport6m_qb2cmd.png";

// Desktop Images
// const airport1 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811016/airport1_ylo3bk.png";
// const airport2 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811041/airports/airport2_tsbdbr.png";
// const airport3 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811041/airports/airport3_bhkje5.png";
// const airport4 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811045/airports/airport4_bkct29.png";
// const airport5 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811044/airports/airport5_t9gscp.png";
// const airport6 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811042/airports/airport6_zwjfug.png";

// Mobile Images
// const airport1M =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811043/airports/airport1m_tvuoqf.png";
// const airport2M =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811048/airports/airport2m_g2uow4.png";
// const airport3M =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811048/airports/airport3m_or6wby.png";
// const airport4M =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811046/airports/airport4m_d1rlkb.png";
// const airport5M =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811046/airports/airport5m_k89n6i.png";
// const airport6M =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811045/airports/airport6m_qb2cmd.png";

const MobileImages = [
  airport1M,
  airport2M,
  airport3M,
  airport4M,
  airport5M,
  airport6M,
];

const AirportTransfers = () => {
  const { width } = useWindowDimensions();

  return (
    <Container className="airport_main_container">
      <div className="header_box">
        <h1>
          Popular Airport <span>Transfers</span>
        </h1>
        <p>
          Help us work better by giving your valuable feedback for the overall
          journey experience.
        </p>
      </div>
      {width >= 1025 ? (
        <div className="airports_box">
          <Row>
            <Col md="6">
              <Row>
                <Col md="12">
                  <div className="airport_inner_box">
                    <img
                      src={airport1}
                      alt="Airport 1"
                      className="airport_img1"
                    />
                    {/* <div className="airport_text_box">
                      <p className="airport_text1">Dubai</p>
                      <p className="airport_text2">UAE</p>
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="airport_inner_box">
                    <img
                      src={airport2}
                      alt="Airport 2"
                      className="airport_img"
                    />
                    {/* <div className="airport_text_box">
                      <p className="airport_text1">Dubai</p>
                      <p className="airport_text2">UAE</p>
                    </div> */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="airport_inner_box">
                    <img
                      src={airport3}
                      alt="Airport 3"
                      className="airport_img"
                    />
                    {/* <div className="airport_text_box">
                      <p className="airport_text1">Dubai</p>
                      <p className="airport_text2">UAE</p>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col md="6">
                  <div className="airport_inner_box">
                    <img
                      src={airport4}
                      alt="Airport 4"
                      className="airport_img"
                    />
                    {/* <div className="airport_text_box">
                      <p className="airport_text1">Dubai</p>
                      <p className="airport_text2">UAE</p>
                    </div> */}
                  </div>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="12">
                      <div className="airport_inner_box">
                        <img
                          src={airport5}
                          alt="Airport 5"
                          className="airport_img"
                        />
                        {/* <div className="airport_text_box">
                          <p className="airport_text1">Dubai</p>
                          <p className="airport_text2">UAE</p>
                        </div> */}
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="airport_inner_box">
                        <img
                          src={airport6}
                          alt="Airport 6"
                          className="airport_img"
                        />
                        {/* <div className="airport_text_box">
                          <p className="airport_text1">Dubai</p>
                          <p className="airport_text2">UAE</p>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="airports_box">
          <Row>
            {MobileImages.length &&
              MobileImages.map((data, _idx) => (
                <Col xs="6" sm="6">
                  <div className="airport_inner_box">
                    <img
                      src={data}
                      alt={`Airport ${_idx}`}
                      className="airport_img1"
                    />
                    {/* <div className="airport_text_box">
                      <p className="airport_text1">Dubai</p>
                      <p className="airport_text2">UAE</p>
                    </div> */}
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default AirportTransfers;
