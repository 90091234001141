import React, { useState } from "react";
import "../../CSS/Form.css";
import arrowUp from "../../assets/arrow-up.png";
import arrowDown from "../../assets/arrow-down.png";

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <label
      htmlFor={props.htmlFor}
      className="different"
      id={props.id}
      onClick={handleToggle}
    >
      <img src={props.icon} alt="icon" />
      <div className="input_field2 ddl-parent">
        <span className="ddl-lv">
          {props.value !== "" ? props.value : props.placeholder}
        </span>
        {open && (
          <div className="ddl-options">
            {props.options &&
              props.options.map((vals, idxs) => (
                <p key={idxs} onClick={props.onChange}>
                  {vals}
                </p>
              ))}
          </div>
        )}
      </div>
      {open ? (
        <img src={arrowUp} alt="arrow-up" className="right-ico-ddl" />
      ) : (
        <img src={arrowDown} alt="arrow-down" className="right-ico-ddl" />
      )}
    </label>
  );
};

export default Dropdown;
