import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/Aboutus.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Withus from "../components/Bookwithus";
import VideoComponent from "../components/Video";
import Header from "../components/header";
import Banner from "../assets/about_banner_xs3nkg.jpg";
import About1 from "../assets/about1_p8emzy.jpg";
import About2 from "../assets/about2_muopah.jpg";
import About3 from "../assets/about3_dcyq3r.jpg";
// Images
// const Banner =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810903/about_banner_xs3nkg.jpg";
// const About1 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810900/about1_p8emzy.jpg";
// const About2 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810902/about2_muopah.jpg";
// const About3 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810900/about3_dcyq3r.jpg";

function Aboutus() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <div
      className="main_about_us_container"
      style={{ backgroundColor: "#FFF" }}
    >
      <NavBar cname="About Us"></NavBar>
      <Header heading="BE DRIVEN BY A PROFESSIONAL" banner={Banner}></Header>
      <Container>
        <div className="about_us_container">
          <div className="left_container">
            <h1 className="upper_heading">About</h1>
            <h1 className="red1">ECS Provider</h1>
            <p className="red">Taxi and Limousine Services</p>
            <p className="text">
              All drivers connected to the ECS ride platform are licensed,
              insured, and skilled to provide a service according to our Six
              Sigma quality standards. In the premium booking classes, all
              drivers are specially trained to serve on a high service-oriented
              level as professional chauffeurs to meet our premium customers'
              expectations to the fullest. Additionally we offer our corporate
              customers the benefit of selecting exclusive limousine service
              partners which we have handpicked according to the passenger's
              wishes.
            </p>
          </div>
        </div>
        <Row>
          <Col>
            <img className="about_img" src={About1} alt="About 1" />
          </Col>
          <Col>
            <img className="about_img" src={About2} alt="About 2" />
          </Col>
        </Row>
        <Withus
          image={About3}
          title="Key Benefits of Booking Service"
          subTitle="with ECS in Saudi Arabia"
          description="When considering travel arrangements for your next trip, We would
              like to highlight why booking with us is wise."
        />
      </Container>
      <div style={{ marginBottom: "100px" }}>
        <VideoComponent
          title="Enjoy unparalleled luxury and"
          subTitle="high-end service with our premier taxi service in KSA."
          description="Indulge in an unforgettable experience of opulence and top-notch service with our top-tier taxi service in the Kingdom of Saudi Arabia. From the moment you step into one of our luxurious vehicles, you'll be treated to a comfortable and secure ride as our professional drivers take you to your destination. We pride ourselves on providing our discerning clients with the highest level of service, ensuring that every journey is a seamless and enjoyable experience. Whether you're traveling for business or pleasure, our premier taxi service is the perfect choice for those who demand the very best."
        />
      </div>
      <Footer />
    </div>
  );
}
export default Aboutus;
