import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Services from "./pages/ourservices";
import Gallery from "./pages/gallery";
import ContactUs from "./pages/contactus";
import MyFleet from "./pages/fleet";
import StripePaymentForm from "./components/StripePaymentForm";
import PrivacyPolicy from "./pages/privacypolicy";
import TermsAndConditions from "./pages/terms";
import BookingPage from "./pages/Bookingpage";
import Paymentpage from "./pages/Paymentpage";
import Aboutus from "./pages/aboutus";
import Home from "./pages/home";
import Loader from "./components/Loader";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/booking",
    element: <BookingPage />,
  },
  {
    path: "/payment",
    element: <Paymentpage />,
  },
  {
    path: "/confirm-pay",
    element: <StripePaymentForm />,
  },
  {
    path: "/aboutus",
    element: <Aboutus />,
  },
  {
    path: "/ourservices",
    element: <Services />,
  },
  {
    path: "/ourgallery",
    element: <Gallery />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/ourfleet",
    element: <MyFleet />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <TermsAndConditions />,
  },
];

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Routes>
        {routes.length &&
          routes.map((data, _idx) => (
            <Route path={data.path} element={data.element} />
          ))}
      </Routes>
    </>
  );
}

export default App;
