import React, { useState, useEffect, lazy, Suspense } from "react";
import "../CSS/Navbar.css";
import { Link } from "react-router-dom";
import getWindowDimensions from "../Utils/screenSize";
import Logo from "../assets/ECS_Logo_xsxxjf.jpg";
import Menu from "../assets/menu_mymfnd.png";

// const Logo =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1716393499/ECS_Logo_xsxxjf.png";
// const Menu =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/menu_mymfnd.png";

function NavBar({ cname }) {
  const { width } = getWindowDimensions();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(cname);
  }, []);

  const showUnderline = (element) => {
    const option = element.innerText;
    setSelectedOption(option);
  };

  const openMenu = () => {
    let box = document.getElementById("mobile_navigation");
    box.style.width = "100%";
  };

  const closeMenu = () => {
    let box = document.getElementById("mobile_navigation");
    box.style.width = "0";
  };

  return width >= 1020 ? (
    <div className="Navbar_App">
      <header className="Navbar_App_header">
        <Link to="/" className="app_logo">
          <img src={Logo} alt="logo" />
        </Link>
        <nav className="desktop_navigation">
          <Link
            to="/"
            onClick={(e) => showUnderline(e.target)}
            className={selectedOption === "Home" ? "active" : ""}
          >
            Home
            {selectedOption === "Home" && <span className="underline"></span>}
          </Link>
          <Link
            to="/ourfleet"
            onClick={(e) => showUnderline(e.target)}
            className={selectedOption === "Our Fleet" ? "active" : ""}
          >
            Our Fleet
            {selectedOption === "Our Fleet" && (
              <span className="underline"></span>
            )}
          </Link>
          <Link
            to="/ourservices"
            onClick={(e) => showUnderline(e.target)}
            className={selectedOption === "Our Services" ? "active" : ""}
          >
            Our Services
            {selectedOption === "Our Services" && (
              <span className="underline"></span>
            )}
          </Link>
          <Link
            to="/aboutus"
            onClick={(e) => showUnderline(e.target)}
            className={selectedOption === "About Us" ? "active" : ""}
          >
            About Us
            {selectedOption === "About Us" && (
              <span className="underline"></span>
            )}
          </Link>
          <Link
            to="/contactus"
            onClick={(e) => showUnderline(e.target)}
            className={selectedOption === "Contact Us" ? "active" : ""}
          >
            Contact Us
            {selectedOption === "Contact Us" && (
              <span className="underline"></span>
            )}
          </Link>
          <Link
            to="/ourgallery"
            onClick={(e) => showUnderline(e.target)}
            className={selectedOption === "Our Gallery" ? "active" : ""}
          >
            Gallery
            {selectedOption === "Our Gallery" && (
              <span className="underline"></span>
            )}
          </Link>
        </nav>
      </header>
    </div>
  ) : (
    <div className="mobile_navbar_app">
      <div className="mobile_bar">
        <Link to="/" className="app_logo">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="nav_menu_options" onClick={openMenu}>
          <img src={Menu} alt="navbar options" />
        </div>
      </div>
      <div className="mobile_navigation" id="mobile_navigation">
        <div className="mobile_box_header">
          <Link to="/" className="app_logo">
            <img src={Logo} alt="logo" />
          </Link>
          <div className="closebtn" onClick={closeMenu}>
            &times;
          </div>
        </div>
        <div className="mobile_box_content">
          <Link to="/" className="mobile_nav_option">
            <p>Home</p>
          </Link>
          <Link to="/ourfleet" className="mobile_nav_option">
            <p>Our Fleet</p>
          </Link>
          <Link to="/ourservices" className="mobile_nav_option">
            <p>Our Services</p>
          </Link>
          <Link to="/aboutus" className="mobile_nav_option">
            <p>About Us</p>
          </Link>
          <Link to="/contactus" className="mobile_nav_option">
            <p>Contact Us</p>
          </Link>
          <Link to="/ourgallery" className="mobile_nav_option">
            <p>Gallery</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
