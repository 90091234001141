import React from "react";
import { InfinitySpin } from "react-loader-spinner";
import "./style.css";

const Loader = (props) => {
  return (
    <div className="loaderOuterBox">
      <InfinitySpin
        visible={props.show ? props.show : true}
        width="200"
        color="#e12e2a"
        ariaLabel="infinity-spin-loading"
      />
    </div>
  );
};

export default Loader;
