import React, { useState } from "react";
import { Alert, Container, Button, Form, Row, Col } from "react-bootstrap";
import Loader from "../Loader";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";

import Logo from "../../assets/ECS_Logo_xsxxjf.jpg"
// const Logo =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1716393499/ECS_Logo_xsxxjf.png";

export const CheckoutForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  let [search] = new useSearchParams(location.search);
  const stripe = useStripe();
  const elements = useElements();
  const backendUrl = "https://ecs-api.markition.com/api/pay-now";
  const initialValues = {
    email: search.get("email"),
    cardholdername: "",
    from: search.get("from"),
    to: search.get("to"),
    time: search.get("time"),
    date: search.get("date"),
    category: search.get("category"),
    type: search.get("type"),
  };

  const [formData, setFormData] = useState(initialValues);
  const [loader, setLoader] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    if (elements == null || stripe == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();

    let stripeToken = null;
    if (submitError?.message) {
      // Show error to your customer
      setErrorShow(true);
      setErrorMessage(submitError.message);
      return;
    } else {
      setErrorShow(false);
      setErrorMessage(false);

      const _CardElement = elements.getElement("card");

      stripeToken = await stripe.createToken(_CardElement).then((data) => data);

      if (stripeToken.error) {
        setLoader(false);
        Swal.fire({
          title: "Error!",
          text: stripeToken.error.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        });
      }
    }

    if (stripeToken.token) {
      setLoader(false);
      const res = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: "sar",
          paymentMethodType: "card",
          stripeToken: stripeToken,
          email: formData.email,
          amount: Number(props.amount),
          cardholdername: formData.cardholdername,
          locationFrom: initialValues.from,
          locationTo: initialValues.to,
          tripTime: initialValues.time,
          tripDate: initialValues.date,
          tripCategory: initialValues.category,
          tripType: initialValues.type,
        }),
      });

      const { client_secret: clientSecret } = await res.json();

      if (res.status === 200) {
        setErrorShow(false);
        Swal.fire({
          title: "Success!",
          text: "Payment Successful. Thank you.",
          icon: "success",
          confirmButtonText: "Book Another Ride",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        }).then(async (ok) => navigate({ pathname: "/" }, { replace: true }));

        setLoader(false);
      } else {
        setErrorShow(true);
        setLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong.",
          icon: "error",
          confirmButtonText: "Go to home",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        }).then(async (ok) => navigate({ pathname: "/" }, { replace: true }));
      }

      // stripe
      //   .confirmCardPayment(
      //     "pi_3MtwBwLkdIwHu7ix28a3tqPa_secret_YrKJUKribcBjcG8HVhfZluoGH",
      //     {
      //       payment_method: {
      //         card: elements,
      //         billing_details: {
      //           name: formData.cardholdername,
      //         },
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     console.log("test -------> ", res);
      //   });

      // const { error } = await stripe.confirmPayment({
      //   elements,
      //   clientSecret,
      //   confirmParams: {
      //     return_url: `${window.location.origin}/success`,
      //   },
      // });

      // if (error) {
      //   setErrorShow(true);
      //   setErrorMessage(error.message);
      //   setLoader(false);
      // } else {
      //   setErrorShow(false);
      //   setLoader(false);
      //   Swal.fire({
      //     title: "Success!",
      //     text: "Payment successful",
      //     icon: "success",
      //     confirmButtonText: "Ok",
      //     confirmButtonColor: "#e12e2a",
      //     allowOutsideClick: false,
      //   });
      // }
    }
  };

  const getAMPM = () => {
    let time = Number(initialValues?.time?.split(":")[0]);
    if (time >= 13 && time <= 24) {
      return "PM";
    } else {
      return "AM";
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Container className="payment-box">
        <Row>
          <Col md="6">
            <div className="header">
              <img src={Logo} alt="Logo" />
              <p>ECSProvider</p>
            </div>
            <div className="content">
              <p className="type-category">
                {initialValues.category} {initialValues.type}
              </p>
            </div>
            <div className="amount">SAR {Number(props.amount).toFixed(2)}</div>
            <div className="location">
              <p>
                {initialValues.from}, {initialValues.to} on {initialValues.date}{" "}
                {initialValues.time} {getAMPM()}
              </p>
            </div>
          </Col>
          <Col md="6">
            <div className="payment-form">
              <p>Pay with card</p>
              <form onSubmit={handleSubmit}>
                <div className="email_input">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    required
                    value={formData.email}
                    onChange={handleOnChange}
                    disabled
                  />
                </div>
                <p className="heading">Card information</p>
                <CardElement
                  options={{ iconStyle: "solid", hidePostalCode: true }}
                />
                <p className="heading">Cardholder name</p>
                <input
                  className="normal-input"
                  type="text"
                  name="cardholdername"
                  placeholder="Full name on card"
                  required
                  value={formData.cardholdername}
                  onChange={handleOnChange}
                />

                <br />
                <div className="d-grid gap-2">
                  <Button
                    className="pay-button"
                    type="submit"
                    variant="danger"
                    disabled={!stripe || !elements}
                  >
                    Pay
                  </Button>
                  {errorShow && <Alert variant="danger">{errorMessage}</Alert>}
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
