import React, { Fragment, useEffect } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import TextBanner from "../components/TextBanner";
import PrivacyContent from "../components/PrivacyContent";

const PrivacyPolicy = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <Fragment>
      <Navbar />
      <TextBanner
        heading="Our policy And Privacy"
        subheading="ECS PROVIDER is committed to protecting the privacy and security of your personal information. This privacy statement describes how we collect and use your personal information and data, in accordance with the EU General Data Protection Regulation (GDPR). "
      />
      <PrivacyContent />
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
