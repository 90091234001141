import React from "react";
import "../CSS/page2.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PaymentDetails from "../components/Paymentcomponent";

function Paymentpage() {
  const test = () => {};
  return (
    <div className="payment_page">
      <NavBar />
      <PaymentDetails />
      <Footer />
    </div>
  );
}

export default Paymentpage;
