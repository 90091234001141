import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/Contact.css";
import emailjs from "@emailjs/browser";
import NavBar from "../components/NavBar";
import Header from "../components/header";
import Footer from "../components/Footer";
import AlertBox from "../components/Alert";
import Loader from "../components/Loader";
import ContactBanner from "../assets/contactBanner_akxiuw.png";
import ContactCar from "../assets/contact_car_j0szbt.png";

// const ContactBanner =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810950/contactBanner_akxiuw.png";
// const ContactCar =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810944/contact_car_j0szbt.png";

const ContactUs = () => {
  const form = useRef();
  const initialValues = {
    fname: "",
    lname: "",
    phone: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [isApiResult, setIsApiResult] = useState({
    show: false,
    type: 1,
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);
    if (
      formData.EM !== "" &&
      formData.lname !== "" &&
      formData.phone !== "" &&
      formData.email !== "" &&
      formData.message !== ""
    ) {
      emailjs
        .sendForm(
          "service_f5u1mae",
          "template_nf1f85b",
          form.current,
          "kX0DktezT0H9vcJ8z"
        )
        .then((response) => {
          setIsLoader(false);
          setIsApiResult({
            show: true,
            type: 1,
            message: "Email sent successfully. We will contact you shortly.",
          });

          formData.name = "";
          formData.email = "";
          formData.message = "";
        })
        .catch((error) => {
          setIsLoader(false);
          setIsApiResult({
            show: true,
            type: 2,
            message: "Something went wrong. Try again later.",
          });
        });
    } else {
      setIsLoader(false);
      setIsApiResult({
        show: true,
        type: 2,
        message: "Please fill required fields.",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <div className="main_contact_container" style={{ backgroundColor: "#FFF" }}>
      {isLoader && <Loader />}
      <NavBar cname="Contact Us"></NavBar>
      <Header heading="Get in Touch" banner={ContactBanner}></Header>
      <Container className="main_contact_body">
        <h1 className="heading">
          Contact Us for Unmatched <span>Service and Support</span>
        </h1>
        <p className="description">
          Have questions about our services or ready to book your next luxury
          travel experience? Don't hesitate to reach out to us at ECS. Our
          dedicated team is here to provide you with the personalized assistance
          and support you need to make your journey extraordinary.
        </p>
        <Row className="contact_form_container">
          <Col md="6">
            <div className="contact_form">
              <form ref={form}>
                <input
                  type="text"
                  name="fname"
                  placeholder="First Name"
                  value={formData.fname}
                  required
                  onChange={handleOnChange}
                  className="contact_input"
                />
                <br />
                <input
                  type="text"
                  name="lname"
                  placeholder="Last Name"
                  required
                  value={formData.lname}
                  onChange={handleOnChange}
                  className="contact_input"
                />
                <br />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  required
                  value={formData.phone}
                  onChange={handleOnChange}
                  className="contact_input"
                />
                <br />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleOnChange}
                  required
                  className="contact_input"
                />
                <br />
                <textarea
                  name="message"
                  placeholder="Message"
                  className="contact_message"
                  required
                  value={formData.message}
                  onChange={handleOnChange}
                />
                <br />
                <button
                  type="button"
                  className="contact_form_button"
                  onClick={handleOnSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
            <AlertBox
              isShow={isApiResult.show}
              type={isApiResult.type}
              message={isApiResult.message}
            />
          </Col>
          <Col md="6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.6258456017977!2d46.695889900000005!3d24.705385500000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03ad9a34291d%3A0x92edf5a88118ecd5!2sEcsprovider!5e0!3m2!1sen!2s!4v1709574527218!5m2!1sen!2s"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
              className="contact_us_map"
            />
          </Col>
        </Row>
      </Container>
      <div className="contact_details_us">
        <Container>
          <div className="contact_info_box">
            <div className="detail_sec_1">
              <div className="detail_info_box">
                <p className="title">Phone :</p>
                <p className="value">+966 53 762 8054</p>
              </div>
              <div className="detail_info_box_2">
                <p className="title">Email :</p>
                <p className="value">info@ecsprovider.com</p>
              </div>
            </div>
            <div className="detail_sec_2">
              <div className="detail_info_box">
                <p className="title">Registered Office:</p>
                <p className="value">
                  Executive Chauffeur Service Provider Pvt. Ltd. Khadiman Rd,
                  beside Near State Bank Of India, 788806, Badarpur, Assam,
                  India
                </p>
              </div>
            </div>
            <div className="detail_sec_2">
              <div className="detail_info_box">
                <p className="title">Corporate Office:</p>
                <p className="value">
                  MPR9+JHF, Al Amirah Sarah Bint Ahmad As Sadiri, Ad Dhubbat,
                  Riyadh 12623, Saudi Arabia
                </p>
              </div>
            </div>
          </div>
        </Container>
        <div className="contact_car_image">
          <img loading="lazy" src={ContactCar} alt="contact car" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
