import axios from "axios";

let BASE_URL = "https://ecs-api.markition.com/api";

const GetVehiclesAPI = async (
  pickup,
  dropoff,
  picktime,
  pickdate,
  passengers,
  categoryType
) => {
  return axios.post(
    `${BASE_URL}/vehicles?pickup=${pickup}&dropoff=${dropoff}&picktime=${picktime}&pickdate=${pickdate}&passengers=${passengers}&category=${categoryType}`
  );
};

const BookingAndCustomer = (
  customerEmail,
  customerFirstName,
  customerLastName,
  airlineName,
  flightNumber,
  driverPickUpSign,
  additionalInfo,
  pickupTime,
  pickupDate,
  locationFrom,
  LocationTo,
  passengers,
  carName,
  bookType,
  hours,
  payType
) => {
  return axios.post(
    `${BASE_URL}/booking-and-customer?customer_email=${customerEmail}&customer_first_name=${customerFirstName}&customer_last_name=${customerLastName}&airline_name=${airlineName}&flight_number=${flightNumber}&driver_pick_up_sign=${driverPickUpSign}&additional_info=${additionalInfo}&pickuptime=${pickupTime}&pickupdate=${pickupDate}&from=${locationFrom}&to=${LocationTo}&passengers=${passengers}&car_name=${carName}&booking_type=${bookType}&hours=${hours}&payType=${payType}`
  );
};

const BookingConfirmation = () => {
  return axios.get(`${BASE_URL}/booking-confirmation`);
};

const API = {
  GetVehiclesAPI,
  BookingAndCustomer,
  BookingConfirmation,
};

export default API;
