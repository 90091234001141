import React from "react";
import "../CSS/Ourprojects.css";
import { Col, Container, Row } from "react-bootstrap";
import useWindowDimensions from "../Utils/screenSize";
import project1 from "../assets/project1_orgklj.jpg";
import project2 from "../assets/project2_tzfzy4.jpg";
import project3 from "../assets/project3_t9oxvj.jpg";
import project4Desktop from "../assets/project4_idghdt.jpg";
import project4Mobile from "../assets/project4_mob_l7gpny.jpg";

// const project1 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810967/project1_orgklj.jpg";
// const project2 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810967/project2_tzfzy4.jpg";
// const project3 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810968/project3_t9oxvj.jpg";
// const project4Desktop =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810971/project4_idghdt.jpg";
// const project4Mobile =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810969/project4_mob_l7gpny.jpg";

const Ourprojects = () => {
  const { width } = useWindowDimensions();

  return (
    <Container className="projects_main_container">
      <div className="header_box">
        <h1>
          Projects We Have <span>Handled Successfully</span>
        </h1>
        <p>
          Help us work better by giving your valuable feedback for the overall
          journey experience.
        </p>
      </div>
      <div className="projects_box">
        <Row>
          <Col xs="6" sm="6" md="4">
            <div className="project_inner_box">
              <img src={project1} alt="Project 1" className="project_img" />
              {/* <span className="project_text">Formula E</span> */}
            </div>
          </Col>
          <Col xs="6" sm="6" md="4">
            <div className="project_inner_box">
              <img src={project2} alt="Project 2" className="project_img" />
              {/* <span className="project_text">FII 7TH Edition</span> */}
            </div>
          </Col>
          <Col xs="6" sm="6" md="4">
            <div className="project_inner_box">
              <img src={project3} alt="Project 3" className="project_img" />
              {/* <span className="project_text">HoneyWell</span> */}
            </div>
          </Col>
          <Col xs="6" sm="6" md="12">
            <div className="project_inner_box">
              <img
                src={width > 1024 ? project4Desktop : project4Mobile}
                alt="Project 4"
                className="project_img"
              />
              {/* <span className="project_text">SAUDI ARAMCO</span> */}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Ourprojects;
