import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../CSS/BookLuxurious.css";
import { useNavigate } from "react-router-dom";
import luxury1 from "../assets/luxury1_dhahmg.png";
import luxury2 from "../assets/luxury2_aaulmi.png";
import luxury3 from "../assets/luxury3_vv80sg.png";
import luxury4 from "../assets/luxury4_x13efr.png";
import luxury5 from "../assets/luxury5_w5e2oa.png";
import luxury6 from "../assets/luxury6_qpurfv.png";

// Images
// const luxury1 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811108/luxurious/luxury1_dhahmg.png";
// const luxury2 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811107/luxurious/luxury2_aaulmi.png";
// const luxury3 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811107/luxurious/luxury3_vv80sg.png";
// const luxury4 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811111/luxurious/luxury4_x13efr.png";
// const luxury5 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811112/luxurious/luxury5_w5e2oa.png";
// const luxury6 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714811114/luxurious/luxury6_qpurfv.png";

const luxuries = [
  {
    image: luxury1,
    name: "Riyadh",
  },
  {
    image: luxury2,
    name: "Makkah",
  },
  {
    image: luxury3,
    name: "Dubai",
  },
  {
    image: luxury4,
    name: "Madina",
  },
  {
    image: luxury5,
    name: "Jeddah",
  },
  {
    image: luxury6,
    name: "AL Khabar",
  },
];

const BookLuxurious = () => {
  const navigate = useNavigate();

  const handleRoute = () => navigate("/ourfleet");

  return (
    <Container className="luxurious_main_container">
      <div className="header_box">
        <h1>
          Book Your next <span>luxurious Trip</span>
        </h1>
        <p>
          Help us work better by giving your valuable feedback for the overall
          journey experience.
        </p>
      </div>
      <div className="luxurious_box">
        <Row>
          {luxuries.length &&
            luxuries.map((data, _idx) => (
              <Col xs="6" sm="6" md="4">
                <div className="luxuries_inner_box">
                  <img
                    src={data.image}
                    alt={data.name}
                    className="luxury_img"
                  />
                  <p className="luxury_text">{data.name}</p>
                </div>
              </Col>
            ))}
        </Row>
        <div className="luxury_btn">
          <div className="btn_inner" onClick={handleRoute}>
            Book Your Fleet Now
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BookLuxurious;
