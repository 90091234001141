import "../App.css";
import NavBar from "../components/NavBar";
import Form from "../components/Form";
import Withus from "../components/Bookwithus";
import Services from "../components/Services";
import Aboutprovider from "../components/Aboutprovider";
import VideoComponent from "../components/Video";
import Footer from "../components/Footer";
import Ourfleet from "../components/Ourfleet";
import Ourprojects from "../components/Ourprojects";
import CommentComponent from "../components/Comments";
import OurClients from "../components/Ourclients";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/shared.css";
import AirportTransfers from "../components/AirportTransfers";
import BookLuxurious from "../components/BookLuxurious";
import Location from "../assets/location_Icon_oyapgw.png";
import Date from "../assets/date_Icon_oqp7mg.png";
import Time from "../assets/Time_Icon_g2mmhg.png";
import Passenger from "../assets/Passenger_Icon_hvh0ev.png";
import Currency from "../assets/Currency_Icon_ylzk8p.png";
import commentpic1 from "../assets/commentpic1_mkmr1a.png";
import commentpic2 from "../assets/commentpic2_yx9pix.png";
import commentpic3 from "../assets/commentpic3_mkohj9.png";

// Images
// const Location =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810959/location_Icon_oyapgw.png";
// const Date =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810943/date_Icon_oqp7mg.png";
// const Time =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810989/Time_Icon_g2mmhg.png";
// const Passenger =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810964/Passenger_Icon_hvh0ev.png";
// const Currency =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810943/Currency_Icon_ylzk8p.png";
// const commentpic1 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810943/commentpic1_mkmr1a.png";
// const commentpic2 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810943/commentpic2_yx9pix.png";
// const commentpic3 =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810944/commentpic3_mkohj9.png";

function Home() {
  const form_inputs = [
    {
      name: "Airport Transfer",
      inputs: [
        [
          {
            for: "from",
            src: Location,
            type: "location",
            id: "from",
            placeholder: "Pick Up",
          },
        ],
        [
          {
            for: "To",
            src: Location,
            type: "location",
            id: "to",
            placeholder: "Drop Off Location",
          },
        ],
        [
          {
            for: "date",
            src: Date,
            type: "date",
            id: "date",
            placeholder: "Pick Up Date",
          },
        ],
        [
          {
            for: "time",
            src: Time,
            type: "time",
            id: "time",
            placeholder: "Pick Up Time",
          },
        ],
        [
          {
            for: "passenger",
            src: Passenger,
            type: "number",
            id: "passenger",
            placeholder: "Passangers",
          },
        ],
        [
          {
            for: "catgory",
            src: Currency,
            type: "category",
            value: ["Economy", "Business", "Luxury"],
            id: "category",
            placeholder: "Choose Category",
          },
        ],
      ],
    },
    {
      name: "Hourly",
      inputs: [
        [
          {
            for: "from",
            src: Location,
            type: "location",
            id: "from",
            placeholder: "Pick Up",
          },
        ],
        [
          {
            for: "To",
            src: Location,
            type: "location",
            id: "to",
            placeholder: "Drop Off Location",
          },
        ],
        [
          {
            for: "date",
            src: Date,
            type: "date",
            id: "date",
            placeholder: "Pick Up Date",
          },
        ],
        [
          {
            for: "time",
            src: Time,
            type: "time",
            id: "time",
            placeholder: "Pick Up Time",
          },
        ],
        [
          {
            for: "hours",
            src: Time,
            type: "number",
            id: "hours",
            placeholder: "Pick Up Hours",
          },
          {
            for: "passenger",
            src: Passenger,
            type: "number",
            id: "passenger",
            placeholder: "Passengers",
          },
        ],
        [
          {
            for: "catgory",
            src: Currency,
            type: "category",
            value: ["Economy", "Business", "Luxury"],
            id: "category",
            placeholder: "Choose Category",
          },
        ],
      ],
    },
    {
      name: "Full Day",
      inputs: [
        [
          {
            for: "from",
            src: Location,
            type: "location",
            id: "from",
            placeholder: "Pick Up",
          },
        ],
        [
          {
            for: "To",
            src: Location,
            type: "location",
            id: "to",
            placeholder: "Drop Off Location",
          },
        ],
        [
          {
            for: "date",
            src: Date,
            type: "date",
            id: "date",
            placeholder: "Pick Up Date",
          },
        ],
        [
          {
            for: "time",
            src: Time,
            type: "time",
            id: "time",
            placeholder: "Pick Up Time",
          },
        ],
        [
          {
            for: "catgory",
            src: Currency,
            type: "category",
            value: ["Economy", "Business", "Luxury"],
            id: "category",
            placeholder: "Choose Category",
          },
        ],
        [
          {
            for: "passenger",
            src: Passenger,
            type: "number",
            id: "passenger",
            placeholder: "Passengers",
          },
        ],
      ],
    },
    // {'name':'Point to Point', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'}],[{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'Pick Up Date'}],[{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'Pick Up Time'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'category', 'placeholder':'USD'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}]]}
  ];

  const commentsList = [
    {
      rating: 4,
      image: commentpic1,
      name: "Yasmine",
      comment:
        "Exquisite service! From the moment I booked to the end of my journey, the team at Executive Chauffeur Service provided unparalleled professionalism and attention to detail. Truly a luxurious experience!",
    },
    {
      rating: 4,
      image: commentpic2,
      name: "Mohammed",
      comment:
        "I can't recommend Executive Chauffeur Service enough! The chauffeur arrived promptly, the vehicle was immaculate, and the journey was smooth and comfortable. Will definitely be booking again!",
    },
    {
      rating: 4,
      image: commentpic3,
      name: "Layla",
      comment:
        "Fantastic! The chauffeur was courteous and skilled, making me feel like a VIP throughout the entire ride. ECS exceeded my expectations in every way.",
    },
    {
      rating: 4,
      image: commentpic1,
      name: "Ali",
      comment:
        "Outstanding professionalism! ECS provided exceptional service from start to finish. The chauffeur was knowledgeable about the area, ensuring a stress-free and enjoyable ride. Will definitely be using them again!",
    },
    // {'rating':4, 'image':commentpic2, 'name':'Merry Lana', 'comment':'Fifth comment in the list. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
    // {'rating':4, 'image':commentpic3, 'name':'Merry Lana', 'comment':'Sixth comment. Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.'},
    // Add more comments as needed
  ];
  return (
    <div className="App" style={{ overflowX: "hidden" }}>
      <header className="App-header">
        <NavBar cname="Home" />
        <Form form_inputs={form_inputs} />
        <Ourfleet />
        <AirportTransfers />
        <Withus
          title="Key Benefits of Booking Service"
          subTitle="with ECS in Saudi Arabia"
          description="When considering travel arrangements for your next trip, We would
              like to highlight why booking with us is wise."
        />
        <Services />
        <Ourprojects />
        <Aboutprovider />
        <BookLuxurious />
        <VideoComponent
          title="Enjoy unparalleled luxury and"
          subTitle="high-end service with our premier taxi service in KSA."
          description="Indulge in an unforgettable experience of opulence and top-notch service with our top-tier taxi service in the Kingdom of Saudi Arabia. From the moment you step into one of our luxurious vehicles, you'll be treated to a comfortable and secure ride as our professional drivers take you to your destination. We pride ourselves on providing our discerning clients with the highest level of service, ensuring that every journey is a seamless and enjoyable experience. Whether you're traveling for business or pleasure, our premier taxi service is the perfect choice for those who demand the very best."
        />
        <OurClients />
        <CommentComponent comments={commentsList} />
        <Footer />
      </header>
    </div>
  );
}

export default Home;
