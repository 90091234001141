import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./checkoutForm";

const StripePaymentForm = () => {
  const stripePromise = loadStripe(
    "pk_live_51NrxMxSH2ONRTC92fXKxzpWFcmt9tvIH1zufc0AHvi0iPmuz93OAWDZx7i2SAsI2oBuq9xRvSPjgOR2prhiUYQ3x00GYU4ack3"
  );

  const location = useLocation();
  let [search] = new useSearchParams(location.search);
  let data = {
    totalAmount: search.get("amount"),
  };

  const options = {
    mode: "payment",
    amount: Number(data.totalAmount),
    currency: "sar",
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm amount={data.totalAmount} />
      </Elements>
    </>
  );
};

export default StripePaymentForm;
