import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../CSS/Form.css";
import { Container, Row, Col } from "react-bootstrap";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import getWindowDimensions from "../Utils/screenSize";
import Dropdown from "./Inputs/dropdown";
import bgImage from "../assets/Background-min_cbftc0_oxdc65.webp";
import PlusIcon from "../assets/plus_ico_v13moe.png";
import MinusIcon from "../assets/minus_ico_ry5gne.png";
import DisaledIco from "../assets/disabled_ico_xyck3r.png";

// Images And Icons
// const Background_Image =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/v1716575256/Background-min_cbftc0_oxdc65.webp";
// const PlusIcon =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/plus_ico_v13moe.png";
// const MinusIcon =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/minus_ico_ry5gne.png";
// const DisaledIco =
//   "https://res.cloudinary.com/dzgzig4vx/image/upload/disabled_ico_xyck3r.png";

const libraries = ["places"];

const Form = ({ form_inputs }) => {
  const { width } = getWindowDimensions();
  const [error, setError] = useState(false);
  const [passengerCount, setPassengerCount] = useState(0);
  var [inputs, Setinputs] = useState({
    hours: 0,
    date: "",
    time: "",
    passenger: 0,
    category: "", // category type
  });
  var [location1, setlocation1] = useState({});
  var [location2, setlocation2] = useState({});
  const [indexx, setindex] = useState(0);
  var [from, setfrom] = useState("");
  var [to, setto] = useState("");
  var [selectedBtn, setSelectedBtn] = useState(form_inputs[0]);
  const handleButtonClick = (btn, index) => {
    setSelectedBtn(btn);
    setindex(index);
  };
  var [place, setPlace] = useState(null);
  var [place2, setPlace2] = useState(null);

  const [ddlValue, setDDLValue] = useState("");

  const onfromChanged = async () => {
    if (place2 !== null && place2.getPlace() !== undefined) {
      const locate1 = place2.getPlace().geometry.location;
      // console.log(location)
      // console.log(place.getPlace().formatted_address)
      console.log("Place changed:", locate1.lat(), locate1.lng());
      setlocation1({ lat: locate1.lat(), lng: locate1.lng() });
      // setfrom([location.lat(), location.lng()])
      setfrom(place2.getPlace().formatted_address);
      console.log(from);
    }
  };

  const ontoChanged = async () => {
    if (place !== null && place.getPlace() !== undefined) {
      const locate2 = place.getPlace().geometry.location;
      // console.log(location)
      // console.log(place.getPlace().formatted_address)
      console.log("Place changed:", locate2.lat(), locate2.lng());
      // setfrom([location.lat(), location.lng()])
      setlocation2({ lat: locate2.lat(), lng: locate2.lng() });
      setto(place.getPlace().formatted_address);
      console.log(to);
    }
  };

  const handleInputChange = (inputKey, value) => {
    Setinputs((prevInputs) => ({
      ...prevInputs,
      [inputKey]: value,
    }));

    setError(false);
  };
  const navigate = useNavigate();

  const handleSubmit = () => {
    const location1String = JSON.stringify(location1);
    const location2String = JSON.stringify(location2);
    const inputlength = Object.keys(inputs).length;
    if (
      to !== "" &&
      from !== "" &&
      inputs.time !== "" &&
      inputs.date !== "" &&
      inputs.passenger !== 0 &&
      inputs.category !== ""
    ) {
      if (inputlength > 5) {
        navigate({
          pathname: "/booking",
          search: createSearchParams({
            picktime: inputs.time,
            pickdate: inputs.date,
            passenger: inputs.passenger,
            hours: inputs.hours,
            from: from,
            to: to,
            location1: location1String,
            location2: location2String,
            selectedBtn: indexx,
            category: inputs.category,
          }).toString(),
          state: inputs,
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <div
      className="main_form_container"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="inner_form_container">
        <Container>
          <Row>
            <Col md="6">
              <div className="left-div">
                <div className="main_text">
                  <h1 className="h1">EXECUTIVE CHAUFFEUR SERVICE</h1>
                  <h1 className="h2">
                    <span>We Offer Luxury Travel Solutions</span>
                  </h1>
                </div>
                {width >= 1025 && (
                  <div className="text">
                    <p>
                      ECS believes travelers shouldn't have to sacrifice luxury
                      and security for convenience, ensuring every journey is an
                      experience of elegance, comfort, and security. From
                      meticulously maintained vehicles to professional
                      chauffeurs, we prioritize your safety and satisfaction at
                      every turn. Say goodbye to compromises and embrace
                      unparalleled luxury with ECS.
                    </p>
                  </div>
                )}
              </div>
            </Col>

            <Col md="6">
              <LoadScript
                googleMapsApiKey="AIzaSyCbq5JUmsTiyxV9Fl4R-AAsRfeJCpJKzxw"
                libraries={libraries}
              >
                <div className="right-div">
                  <div className="form_btns">
                    {form_inputs.map((form_input, index) => (
                      <button
                        className={`form_btn${index + 1} ${
                          selectedBtn.name === form_input.name ? "active" : ""
                        }`}
                        onClick={() => handleButtonClick(form_input, index)}
                      >
                        {form_input.name}
                      </button>
                    ))}
                  </div>
                  <form>
                    <div className="form_inputs">
                      {selectedBtn.inputs.map((inputs, indx) => (
                        <div key={indx} className="input">
                          {inputs.length === 2 ? (
                            <div
                              className={`input_field ${
                                error && "input_field_error"
                              } ${
                                width < 1024 &&
                                inputs.length === 2 &&
                                "mobileBoxView"
                              }`}
                            >
                              {inputs[0].type !== "location" &&
                              inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className={`${
                                    width < 1024 &&
                                    inputs.length === 2 &&
                                    `mobileView`
                                  }`}
                                >
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field1">
                                    <input
                                      type={inputs[0].type}
                                      id={inputs[0].id}
                                      placeholder={inputs[0].placeholder}
                                      onChange={(e) =>
                                        handleInputChange(
                                          inputs[0].id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </label>
                              ) : inputs[0].type === "date" ? (
                                <label htmlFor={inputs[0].for}>
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field1">
                                    <input
                                      placeholder={inputs[0].placeholder}
                                      class="textbox-n"
                                      type="text"
                                      onMouseEnter={(e) =>
                                        (e.target.type = "date")
                                      }
                                      onMouseLeave={(e) =>
                                        (e.target.type = "text")
                                      }
                                      id="session-date"
                                      name="session-date"
                                      onChange={(e) =>
                                        handleInputChange(
                                          inputs[0].id,
                                          e.target.value
                                        )
                                      }
                                    ></input>
                                  </div>
                                </label>
                              ) : (
                                <label htmlFor={inputs[0].for}>
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field1">
                                    <Autocomplete
                                      onLoad={(autocomplete) =>
                                        setPlace(autocomplete)
                                      }
                                      onPlaceChanged={onfromChanged}
                                    >
                                      <input
                                        type="text"
                                        placeholder={inputs[0].placeholder}
                                        onChange={(e) =>
                                          handleInputChange(
                                            inputs[0].id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Autocomplete>
                                  </div>
                                </label>
                              )}
                              {inputs[1].type !== "location" &&
                              inputs[1].type !== "time" &&
                              inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[1].for}
                                  className={`different1 ${
                                    width < 1024 &&
                                    inputs.length === 2 &&
                                    `mobileView`
                                  }`}
                                >
                                  <img src={inputs[1].src} alt="" />
                                  <div className="input_field1">
                                    <span className="text_place">
                                      {inputs[1].placeholder}
                                    </span>
                                  </div>
                                  <div className="main_count_box">
                                    <img
                                      src={
                                        passengerCount <= 0 + 1
                                          ? DisaledIco
                                          : MinusIcon
                                      }
                                      alt="minus_ico"
                                      onClick={() => {
                                        if (passengerCount !== 0) {
                                          handleInputChange(
                                            inputs[1].id,
                                            passengerCount
                                          );
                                          setPassengerCount(passengerCount - 1);
                                        }
                                      }}
                                    />
                                    <span>
                                      {passengerCount <= 0
                                        ? 0
                                        : passengerCount - 1}
                                    </span>
                                    <img
                                      src={PlusIcon}
                                      alt="plus_ico"
                                      onClick={() => {
                                        handleInputChange(
                                          inputs[1].id,
                                          passengerCount
                                        );
                                        setPassengerCount(passengerCount + 1);
                                      }}
                                    />
                                  </div>
                                </label>
                              ) : inputs[1].type === "time" ? (
                                <label
                                  htmlFor={inputs[1].for}
                                  className="second"
                                >
                                  <img src={inputs[1].src} alt="" />
                                  <div className="input_field1">
                                    <input
                                      placeholder={inputs[1].placeholder}
                                      class="textbox-n"
                                      type="text"
                                      onMouseEnter={(e) =>
                                        (e.target.type = "time")
                                      }
                                      onMouseLeave={(e) =>
                                        (e.target.type = "text")
                                      }
                                      id="session-time"
                                      name="session-time"
                                      onChange={(e) =>
                                        handleInputChange(
                                          inputs[0].id,
                                          e.target.value
                                        )
                                      }
                                    ></input>
                                  </div>
                                </label>
                              ) : (
                                <label htmlFor={inputs[1].for}>
                                  <img src={inputs[1].src} alt="" />
                                  <div className="input_field1">
                                    <Autocomplete
                                      onLoad={(autocomplete) =>
                                        setPlace(autocomplete)
                                      }
                                      onPlaceChanged={ontoChanged}
                                    >
                                      <input
                                        type="text"
                                        placeholder={inputs[1].placeholder}
                                        onChange={(e) =>
                                          handleInputChange(
                                            inputs[1].id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Autocomplete>
                                  </div>
                                </label>
                              )}
                            </div>
                          ) : (
                            <div
                              className={`input_field ${
                                error && "input_field_error"
                              }`}
                              style={
                                width < 1024 &&
                                indexx === 1 &&
                                inputs[0].type === "category"
                                  ? { marginTop: "90px" }
                                  : {}
                              }
                            >
                              {inputs[0].type !== "category" &&
                              inputs[0].type !== "location" &&
                              inputs[0].type !== "time" &&
                              inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field2">
                                    <span className="text_place">
                                      {inputs[0].placeholder}
                                    </span>
                                  </div>
                                  <div className="main_count_box">
                                    <img
                                      src={
                                        passengerCount <= 0 + 1
                                          ? DisaledIco
                                          : MinusIcon
                                      }
                                      alt="minus_ico"
                                      onClick={() => {
                                        if (passengerCount !== 0) {
                                          handleInputChange(
                                            inputs[0].id,
                                            passengerCount
                                          );
                                          setPassengerCount(passengerCount - 1);
                                        }
                                      }}
                                    />
                                    <span>
                                      {passengerCount <= 0
                                        ? 0
                                        : passengerCount - 1}
                                    </span>
                                    <img
                                      src={PlusIcon}
                                      alt="plus_ico"
                                      onClick={() => {
                                        handleInputChange(
                                          inputs[0].id,
                                          passengerCount
                                        );
                                        setPassengerCount(passengerCount + 1);
                                      }}
                                    />
                                  </div>
                                </label>
                              ) : inputs[0].type !== "location" &&
                                inputs[0].type !== "time" &&
                                inputs[0].type !== "date" ? (
                                <Dropdown
                                  htmlFor={inputs[0].for}
                                  id={inputs[0].id}
                                  icon={inputs[0].src}
                                  placeholder={inputs[0].placeholder}
                                  options={inputs[0].value}
                                  value={ddlValue}
                                  onChange={(e) => {
                                    setDDLValue(e.target.innerHTML);
                                    handleInputChange(
                                      inputs[0].id,
                                      e.target.innerHTML
                                    );
                                  }}
                                />
                              ) : inputs[0].type !== "time" &&
                                inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field2">
                                    {inputs[0].id === "from" ? (
                                      <Autocomplete
                                        onLoad={(autocomplete) =>
                                          setPlace2(autocomplete)
                                        }
                                        onPlaceChanged={onfromChanged}
                                      >
                                        <input
                                          type="text"
                                          placeholder={inputs[0].placeholder}
                                          onChange={(e) =>
                                            handleInputChange(
                                              inputs[0].id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Autocomplete>
                                    ) : (
                                      <Autocomplete
                                        onLoad={(autocomplete) =>
                                          setPlace(autocomplete)
                                        }
                                        onPlaceChanged={ontoChanged}
                                      >
                                        <input
                                          type="text"
                                          placeholder={inputs[0].placeholder}
                                          onChange={(e) =>
                                            handleInputChange(
                                              inputs[0].id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Autocomplete>
                                    )}
                                  </div>
                                </label>
                              ) : inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field2">
                                    <input
                                      placeholder={inputs[0].placeholder}
                                      class="textbox-n"
                                      type="text"
                                      onMouseEnter={(e) =>
                                        (e.target.type = "time")
                                      }
                                      onMouseLeave={(e) =>
                                        (e.target.type = "time")
                                      }
                                      id="session-time"
                                      name="session-time"
                                      onChange={(e) =>
                                        handleInputChange(
                                          inputs[0].id,
                                          e.target.value
                                        )
                                      }
                                      value={inputs.picktime}
                                    ></input>
                                  </div>
                                </label>
                              ) : (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <img src={inputs[0].src} alt="" />
                                  <div className="input_field2">
                                    <input
                                      placeholder={inputs[0].placeholder}
                                      class="textbox-n"
                                      type="text"
                                      min={new Date()
                                        .toISOString()
                                        .slice(0, 10)}
                                      onMouseEnter={(e) =>
                                        (e.target.type = "date")
                                      }
                                      onMouseLeave={(e) =>
                                        (e.target.type = "date")
                                      }
                                      // onMouseLeave={(e) => e.target.type = 'text'}
                                      id="session-date"
                                      name="session-date"
                                      onChange={(e) =>
                                        handleInputChange(
                                          inputs[0].id,
                                          e.target.value
                                        )
                                      }
                                    ></input>
                                  </div>
                                </label>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={handleSubmit}
                        style={
                          width < 1024 && indexx === 1
                            ? {
                                marginTop: "60px",
                                marginBottom: "10px",
                              }
                            : {}
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </LoadScript>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Form;
